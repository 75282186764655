<template>
  <div class="form_input">
    <template
      v-if="
        !(
          propertyType == 'datetime' ||
          propertyType == 'date' ||
          propertyType == 'time' ||
          propertyType == 'base64' ||
          propertyType == 'point' ||
          propertyType == 'polygon'
        )
      "
    >
      <b-input-group :prepend="prefix" :append="suffix" :size="size" style="min-width: 155px">
        <b-input-group-prepend>
          <slot name="prepend"></slot>
        </b-input-group-prepend>

        <template v-if="propertyType == 'string' || propertyType == 'text'">
          <b-form-textarea
            v-if="_.get(text_config, ['textarea']) == true"
            v-model="param_display"
            :size="size"
            :disabled="disabled"
            :autocomplete="$d.randStr()"
            :placeholder="placeholder"
            rows="2"
            max-rows="9"
          ></b-form-textarea>
          <!-- <swatches-picker v-else-if="_.get(text_config, ['color_picker']) == true" v-model="param_display" /> -->
          <chrome-picker v-else-if="_.get(text_config, ['color_picker']) == true" v-model="param_display" />
          <!-- <RichTextEditor v-else-if="_.get(text_config, ['rich_text_editor']) == true" :content.sync="param_display" /> -->
          <b-form-input
            v-else
            type="text"
            v-model="param_display"
            :size="size"
            :disabled="disabled"
            :autocomplete="$d.randStr()"
            :placeholder="placeholder"
          ></b-form-input>
        </template>
        <!-- TODO: use JSON editor, must output json, not string! -->
        <!-- <b-form-input
          v-if="propertyType == 'json'"
          type="text"
          v-model="param_display"
          :size="size"
          :disabled="disabled"
          :autocomplete="$d.randStr()"
          :placeholder="placeholder"
        ></b-form-input> -->
        <VJsoneditor v-if="propertyType == 'json'" v-model="param_display" :plus="false" height="200px" :options="{ mode: 'code' }" />
        <template v-if="propertyType == 'url'">
          <b-form-input
            type="text"
            v-model="param_display"
            :size="size"
            :disabled="disabled"
            :autocomplete="$d.randStr()"
            :placeholder="placeholder"
          ></b-form-input>

          <div v-if="param_display" style="margin-top: 10px">
            <b-embed type="iframe" aspect="16by9" :src="param_display" allowfullscreen></b-embed>
          </div>
          <!-- <embed v-if="param_display" :src="param_display" width="100%" height="100%"/> -->
        </template>
        <b-form-input
          type="number"
          v-if="propertyType == 'number'"
          v-model="param_display"
          :step="!_.isNil(_.get(number_config, ['scale'])) ? 1 / Math.pow(10, number_config['scale']) : 1"
          :min="!_.isNil(_.get(number_config, ['min'])) ? number_config['min'] : null"
          :max="!_.isNil(_.get(number_config, ['max'])) ? number_config['max'] : null"
          :autocomplete="$d.randStr()"
          :disabled="disabled"
          :placeholder="placeholder"
        ></b-form-input>
        <b-form-input
          v-if="propertyType == 'id'"
          type="number"
          v-model="param_display"
          :autocomplete="$d.randStr()"
          :disabled="disabled"
          :placeholder="placeholder"
        ></b-form-input>
        <!-- <b-form-input
      v-if="propertyType == 'boolean'"
      type="number"
      v-model="param_display"
      step="1"
      min="0"
      max="1"
      :disabled="disabled"
      :autocomplete="$d.randStr()"
        ></b-form-input>-->
        <template v-if="propertyType == 'boolean'">
          <b-button-group size="sm">
            <c-switch
              class="mx-2"
              :class="{ nulled_switch: param_display == null }"
              v-model="param_display"
              color="success"
              :size="size == 'sm' ? 'sm' : 'lg'"
              :disabled="disabled"
              label
              outline
              :dataOn="'\u2713'"
              :dataOff="'\u2715'"
              :value="true"
            />
            <b-button
              v-if="param_display !== null"
              @click="
                () => {
                  param_display = null;
                }
              "
              variant="default"
              :disabled="disabled"
              v-b-tooltip.hover
              title="Clear"
              size="sm"
            >
              <i class="fa fa-ban"></i>
            </b-button>
          </b-button-group>
        </template>

        <b-input-group-append>
          <slot name="append"></slot>
        </b-input-group-append>
      </b-input-group>
    </template>

    <!-- TODO: Use google maps instead -->
    <!-- <b-form-input v-if="propertyType == 'point'" type="text" v-model="param_display"></b-form-input> -->
    <template v-if="propertyType == 'point' || propertyType == 'polygon'">
      <b-input-group :prepend="prefix" :append="suffix" :size="size">
        <b-input-group-prepend>
          <slot name="prepend"></slot>
        </b-input-group-prepend>
        <b-form-input
          v-if="propertyType == 'point'"
          type="text"
          :value="param_display"
          @input="setParamDisplayDebounced"
          :autocomplete="$d.randStr()"
          :disabled="disabled"
          :placeholder="placeholder ? placeholder : 'GPS Coordinate'"
        ></b-form-input>

        <b-input-group-append>
          <slot name="append"></slot>
        </b-input-group-append>
      </b-input-group>

      <GmapAutocomplete
        v-if="propertyType == 'point'"
        @place_changed="setPlace"
        :placeholder="'Search Place'"
        :value="point_search"
        style="width: 100%"
        :disabled="disabled"
        class="form-control"
      ></GmapAutocomplete>
      <!-- class="form-control" mimics CoreUI or bootstrap style -->
      <b-form-group v-if="propertyType == 'polygon' && map.polygon.length > 0" label="Edit Polygon" :label-cols="12" :label-cols-sm="3">
        <c-switch
          class="mx-2"
          v-model="map.edit_polygon"
          color="success"
          :size="size == 'sm' ? 'sm' : 'lg'"
          :disabled="disabled"
          label
          outline
          :dataOn="'\u2713'"
          :dataOff="'\u2715'"
          :value="true"
        />
      </b-form-group>
      <!-- <b-form-input
      v-if="propertyType == 'polygon'"
      type="text"
      v-model="param_display"
      @input="(e) => {readPolygonGeojson(JSON.stringify(e))}"
      ></b-form-input>-->
      <b-button :variant="isMapShow ? 'outline-secondary' :  'outline-primary' " size="sm" @click="toggle">
        <span v-if="isMapShow">Hide Map</span>
        <span v-if="!isMapShow">Show Map</span>
      </b-button>
      <br />
      <GmapMap v-if="isMapShow || isMapShown" v-show="isMapShow" :center="map.center" :zoom="12" map-type-id="roadmap" style="width: auto; height: 300px" ref="map">
        <GmapMarker
          v-if="propertyType == 'point'"
          :position="map.point"
          :draggable="!disabled"
          :clickable="false"
          @position_changed="onPositionChangedDebounced($event)"
        />

        <gmap-polygon
          v-if="propertyType == 'polygon' && map.polygon.length > 0"
          :paths="map.polygon"
          :editable="!disabled && map.edit_polygon"
          @paths_changed="onPolygonChangedDebounced($event)"
          @rightclick="handleClickForDelete"
          ref="polygon"
        ></gmap-polygon>
      </GmapMap>
    </template>

    <template v-if="propertyType == 'base64' || propertyType == 'base64_dataurl' || propertyType == 'blob' || propertyType == 'file'">
      <b-input-group :prepend="prefix" :append="suffix" :size="size">
        <b-input-group-prepend>
          <slot name="prepend"></slot>
        </b-input-group-prepend>
        <b-form-file
          :disabled="disabled || loadingBase64"
          v-model="file"
          :accept="base64_config && (base64_config['mimetype'] || (base64_config['mimetypes'] ? base64_config['mimetypes'].join(',') : null))"
          @input="onFile()"
          :state="Boolean(param || file_tempurl_param)"
          :placeholder="placeholder || (param || file_tempurl_param ? (file ? `File Chosen - ${file['name']}` : 'File Uploaded') : 'No File Chosen')"
        >
          <template slot="file-name" slot-scope="{ names }">
            <!-- <b-badge variant="dark">{{ names[0] }}</b-badge> -->
            <!-- <b-badge v-if="names.length > 1" variant="dark" class="ml-1"> + {{ names.length - 1 }} More files </b-badge> -->
            <span v-if="names.length == 0">
              No File Chosen
            </span>
            <span v-else-if="file"> File Chosen - {{ file['name'] }} </span>
          </template>
        </b-form-file>
        <!-- accept="image/*" -->
        <b-input-group-append>
          <slot name="append"></slot>
          <b-button-group>
            <b-button
              v-if="!_.isNil(param_display || file_tempurl_param)"
              :disabled="disabled"
              :href="param_display || file_tempurl_param"
              download="download"
              target="_blank"
              variant="outline-primary"
              v-b-tooltip.hover
              title="Download"
            >
              <i class="fas fa-download"></i>
            </b-button>
            <b-button
              v-if="!_.isNil(param)"
              :disabled="disabled"
              @click="
                setNull();
                file = null;
              "
              variant="outline-danger"
              v-b-tooltip.hover
              title="Clear"
            >
              <i class="fas fa-ban"></i>
            </b-button>
            <b-button v-if="!_.isNil(prev_param)" :disabled="disabled" @click="unsetNull()" variant="outline-warning" v-b-tooltip.hover title="Undo">
              <i class="fas fa-undo"></i>
            </b-button>
          </b-button-group>
        </b-input-group-append>
        <!-- For blob, data is sent via base64 string without MIME type/ extension, however, bytes should be used instead for effeciency, however it requires a change in API data format -->
        <!-- https://stackoverflow.com/questions/50774176/sending-file-and-json-in-post-multipart-form-data-request-with-axios/50774380 -->
      </b-input-group>
      <!-- TODO: need to check if the mimetype is image, etc -->
      <div v-if="file && allow_crop" style="margin-top:10px;margin-left:auto;margin-right:auto;display:block">
        <!-- <div> -->
        <vue-croppie
          ref="croppieRef"
          class="croppie"
          :class="{ circle: _.get(base64_config, ['circle']) }"
          v-bind="croppie_options"
          @update="onCropDebounced"
        ></vue-croppie>
      </div>
      <div v-else-if="param_display">
        <img
          v-if="$d.isBase64Image(param_display)"
          :src="param_display"
          :class="{ circle: _.get(base64_config, ['circle']) }"
          alt
          style="margin-top:10px;max-width:100%;max-height:500px;margin-left:auto;margin-right:auto;display:block"
        />
        <b-embed v-else-if="$d.isBase64Pdf(param_display)" type="iframe" aspect="16by9" :src="param_display" allowfullscreen></b-embed>
        <b-embed v-else-if="$d.isBase64Video(param_display)" type="video" aspect="16by9" :src="param_display" allowfullscreen controls></b-embed>
      </div>
      <div v-else-if="file_tempurl_param">
        <!-- NOTE: file_tempurl_param is from the past or currently existing in database, new url will only update if submitted -->
        <img
          v-if="$d.isImageUrl(file_tempurl_param)"
          :src="file_tempurl_param"
          :class="{ circle: _.get(base64_config, ['circle']) }"
          alt
          style="margin-top:10px;max-width:100%;max-height:500px;margin-left:auto;margin-right:auto;display:block"
        />
        <b-embed v-else-if="$d.isPdfUrl(file_tempurl_param)" type="iframe" aspect="16by9" :src="file_tempurl_param" allowfullscreen></b-embed>
        <b-embed v-else-if="$d.isVideoUrl(file_tempurl_param)" type="video" aspect="16by9" :src="file_tempurl_param" allowfullscreen controls></b-embed>
      </div>
      <!-- <div>{{file_tempurl_param}}</div> -->
    </template>

    <template v-if="propertyType == 'datetime' || propertyType == 'date' || propertyType == 'time'">
      <VueCtkDateTimePicker
        :disabled="disabled"
        v-model="param_display"
        :format="propertyType == 'datetime' ? picker_datetime_format : propertyType == 'date' ? $d.date_format : propertyType == 'time' ? $d.time_format : null"
        :output-format="
          propertyType == 'datetime' ? $d.datetime_format : propertyType == 'date' ? $d.date_format : propertyType == 'time' ? $d.time_format : null
        "
        :onlyDate="propertyType == 'date' ? true : false"
        :onlyTime="propertyType == 'time' ? true : false"
        :no-value-to-custom-elem="true"
      >
        <!-- :autoClose="propertyType == 'datetime' ? false : true" -->
        <div>
          <b-input-group>
            <b-input-group-prepend>
              <slot name="prepend"></slot>
            </b-input-group-prepend>
            <b-form-input
              type="text"
              :size="size"
              :autocomplete="$d.randStr()"
              :placeholder="
                placeholder
                  ? placeholder
                  : propertyType == 'datetime'
                  ? picker_datetime_format
                  : propertyType == 'date'
                  ? $d.date_format
                  : propertyType == 'time'
                  ? $d.time_format
                  : null
              "
              :value="param_display"
              @input="setParamDisplayDebounced"
              :disabled="disabled"
            ></b-form-input>
            <b-input-group-append>
              <slot name="append"></slot>
            </b-input-group-append>
          </b-input-group>
        </div>
      </VueCtkDateTimePicker>
    </template>
  </div>
</template>

<script>
import { Switch as cSwitch } from '@coreui/vue';
import { setTimeout } from 'timers';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import { QInput } from 'quasar';
import { Swatches, Chrome } from 'vue-color';
import VJsoneditor from 'v-jsoneditor';

import _ from 'lodash';
import moment from 'moment';

////// VueCroppie
import { VueCroppieComponent } from 'vue-croppie';
import 'croppie/croppie.css'; // import the croppie css manually

export default {
  name: 'FormInput',
  components: {
    cSwitch,
    VueCtkDateTimePicker,
    QInput,
    'vue-croppie': VueCroppieComponent,
    'swatches-picker': Swatches,
    'chrome-picker': Chrome,
    RichTextEditor: () => import('./RichTextEditor/'),
    VJsoneditor,
  },
  props: {
    propertyType: {
      type: String,
      default: 'string',
    },
    extParam: {
      required: true,
    },
    file_tempurl_param: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    point_search: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    number_config: {
      type: Object,
    },
    text_config: {
      type: Object,
    },
    base64_config: {
      type: Object,
    },
    crop_config: {
      type: Object,
    },
    semicolon_as_array: {
      type: Boolean,
      default: false,
    },
    default_map_show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      prev_param: null,
      prev_file: null,
      param: null,

      map: {
        center: { lat: 3.0467364, lng: 101.6347433 },
        point: { lat: 3.0467364, lng: 101.6347433 },
        polygon: [],
        edit_polygon: false,
      },

      loadingBase64: false,

      file: null,
      isMapShown: false,
      isMapShow: false,
    };
  },
  computed: {
    picker_datetime_format() {
      // return this.$d.datetime_format
      return this.$d.date_format + ' ' + this.$d.time_format;
      //So that it only shows pure date and pure time in the picker
    },
    param_display: {
      //ref: https://github.com/vuejs/vue/issues/6592#issuecomment-411898140
      // getter
      get: function() {
        //param shown to user
        let output_param = this.param;

        if (this.propertyType == 'boolean') {
          if (!_.isNil(this.param)) {
            output_param = this.param ? true : false;
          } else {
            output_param = null;
          }
        }

        if (this.propertyType == 'string') {
          if (_.get(this.text_config, ['color_picker']) == true) {
            if (_.isNil(this.param)) {
              console.log('color is invalid');
              // output_param = '#FFFFFF'; //initialize as white
              output_param = '#CCCCCC'; //initialize as grey
              //TODO: need to better manage the default color, and ability to null colors instead of forcing a color
            }
          }
        }

        if (this.propertyType == 'blob') {
          //can only work if a single mimetype is defined, otherwise can't restore file extension and will be corrupted for some reason
          if (!_.isNil(this.base64_config, ['mimetype'])) {
            //must have
            if (!_.isNil(this.param)) {
              output_param = this.$d.base64ToDataUrl(this.param, this.base64_config['mimetype']);
            }
          }
        }

        if (this.propertyType == 'file') {
          if (!_.isNil(this.param) && !_.isNil(this.param, ['base64'])) {
            output_param = this.param['base64'];
          }
        }

        if (!_.isNil(this.param) && this.param != '') {
          //TODO: should probably handle null or empty strings separately
          //sanitize if filled
          if (this.semicolon_as_array == true) {
            if (Array.isArray(this.param)) {
              output_param = this.param.join(';');
              console.log('output array to semicolon', output_param);
            } else {
              console.log('output array to semicolon, but not an array', output_param);
            }
          }
          if (this.propertyType == 'point') {
            if (Array.isArray(this.param['coordinates']) && this.param['coordinates'][0] && this.param['coordinates'][1]) {
              output_param = `${Number(this.param['coordinates'][1]).toFixed(8)}, ${Number(this.param['coordinates'][0]).toFixed(8)}`;
              // output_param = `${Number(this.param['coordinates'][1])}, ${Number(this.param['coordinates'][0])}`;
            }
          }

          if (this.propertyType == 'datetime') {
            output_param = moment(this.param, this.$d.datetime_format).format(this.$d.datetime_format);
          }
          if (this.propertyType == 'date') {
            output_param = moment(this.param, this.$d.date_format).format(this.$d.date_format);
          }
          if (this.propertyType == 'time') {
            output_param = moment(this.param, this.$d.time_format).format(this.$d.time_format);
          }
        }

        // console.log('output_param', this.param, output_param);
        return output_param;
        // return !!this.param; //cast to bool
      },
      // setter
      set: function(newValue) {
        //param set from user input
        //TODO: may consider splitting transform/sanitization code into separate debounce

        console.log('from newValue', newValue);
        if (newValue) {
          if (this.semicolon_as_array == true) {
            newValue = String(newValue).split(';');
            console.log('input semicolon as array', newValue);
          }

          if (this.propertyType == 'point') {
            let parsedGPS = String(newValue).split(',');
            if (Number(parsedGPS[0]) && Number(parsedGPS[1])) {
              // let lngLatArray = [_.round(Number(parsedGPS[1]), 8), _.round(Number(parsedGPS[0]), 8)];
              let lngLatArray = [Number(parsedGPS[1]), Number(parsedGPS[0])];
              newValue = this.lngLatToGeojson(lngLatArray);
              this.setPoint(lngLatArray);
            } else {
              newValue = null; //invalid coordinate, clear it
            }
          }

          if (this.propertyType == 'datetime') {
            let m = moment(newValue, this.picker_datetime_format);
            if (m.isValid()) {
              newValue = this.$d.momentFormatDatetime(m);
            }
          }
          if (this.propertyType == 'date') {
            let m = moment(newValue, this.$d.date_format);
            if (m.isValid()) {
              newValue = this.$d.momentFormatDate(m);
            }
          }
          if (this.propertyType == 'time') {
            let m = moment(newValue, this.$d.time_format);
            if (m.isValid()) {
              newValue = this.$d.momentFormatTime(m);
            }
          }

          if (this.propertyType == 'boolean') {
            if (!_.isNil(newValue)) {
              newValue = newValue ? true : false;
            } else {
              newValue = null;
            }
          }

          if (this.propertyType == 'string') {
            if (_.get(this.text_config, ['color_picker']) == true) {
              newValue = _.get(newValue, ['hex8']); //with alpha
            }
          }
        }

        console.log('to newValue', newValue);
        // newValue = !!newValue; //cast to bool
        this.$set(this, 'param', newValue);
        this.param = newValue;
      },
    },
    allow_crop() {
      return this.base64_config && this.base64_config['allow_crop'];
    },
    croppie_options() {
      return {
        enableOrientation: true,
        mouseWheelZoom: false,
        enableResize: false,
        viewport: {
          width: _.get(this.crop_config, ['width'], 200),
          height: _.get(this.crop_config, ['height'], 200),
          // type: 'circle' //don't actually crop circle
        },
        boundary: {
          width: _.get(this.crop_config, ['width'], 200),
          height: _.get(this.crop_config, ['height'], 200),
        },
      };
    },
  },
  watch: {
    param(value) {
      //data going out
      console.log('Param changed, update extParam');
      if (!this.disabled) {
        this.$emit('update:extParam', this.param);
      }
      this.$emit('input', this.param);
    },
    extParam(to, from) {
      //data coming in
      // console.log('extParam changed in FormInput.vue www',from, to);

      if (!_.isEqual(this.param, to)) {
        console.log('extParam changed in FormInput.vue', to);
        this.init();
        this.initMap();
        this.$forceUpdate();
      }
    },
  },
  created() {
    this._ = _;

    this.onCropDebounced = _.debounce(this.onCrop, 250);
    this.onPositionChangedDebounced = _.debounce(this.onPositionChanged, 250);
    this.onPolygonChangedDebounced = _.debounce(this.onPolygonChanged, 250);
    this.setPointDebounced = _.debounce(this.setPoint, 1000);
    this.setParamDisplayDebounced = _.debounce(this.setParamDisplay, 1500);

    if(this.default_map_show){
      this.isMapShow = true
    }
  },
  mounted() {
    this.init();
    this.initMap();
  },
  methods: {
    init() {
      this.param = this.extParam; //somehow this must be in mounted, otherwise boolean switch will be set to false...
    },

    initMap() {
      if (this.propertyType == 'point') {
        if (this.param) {
          var geojson_point_object = this.param;
          // var geojson_point_object = JSON.parse(this.param);
          this.map.point.lng = geojson_point_object.coordinates[0];
          this.map.point.lat = geojson_point_object.coordinates[1];

          console.log('changed', this.map.point.lng, this.map.point.lat);
          this.map.center = Object.assign({}, this.map.point);
        }
      }

      if (this.propertyType == 'polygon') {
        if (this.param) {
          this.readPolygonGeojson(JSON.stringify(this.param));
        } else {
          ///Initialize a Triangle Polygon

          this.$refs.map.$mapPromise.then(map => {
            // obtain the bounds, so we can guess how big the polygon should be
            setTimeout(() => {
              //work-around as map not fully rendered yet
              var bounds = map.getBounds();
              var northEast = bounds.getNorthEast();
              var southWest = bounds.getSouthWest();
              var center = bounds.getCenter();
              var degree = this.map.polygon.length + 1;
              var f = Math.pow(0.66, degree);

              // Draw a triangle. Use f to control the size of the triangle.
              // i.e., every time we add a path, we reduce the size of the triangle
              var path = [
                {
                  lng: center.lng(),
                  lat: (1 - f) * center.lat() + f * northEast.lat(),
                },
                {
                  lng: (1 - f) * center.lng() + f * southWest.lng(),
                  lat: (1 - f) * center.lat() + f * southWest.lat(),
                },
                {
                  lng: (1 - f) * center.lng() + f * northEast.lng(),
                  lat: (1 - f) * center.lat() + f * southWest.lat(),
                },
              ];

              this.map.polygon.push(path);
            }, 500);
          });
        }
      }
    },

    setParamDisplay(input) {
      this.param_display = input;
    },

    getBase64(file, is_data_url = true) {
      //ref: https://stackoverflow.com/a/52311051/3553367
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (!is_data_url) {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            if (encoded.length % 4 > 0) {
              encoded += '='.repeat(4 - (encoded.length % 4));
            }
            resolve(encoded); //purely base64 only, dropped mime types / metadata
          } else {
            resolve(reader.result); //dataurl with base64
          }
        };
        reader.onerror = error => reject(error);
      });
    },

    setNull() {
      this.prev_param = _.cloneDeep(this.param);
      this.param = null;
      this.prev_file = this.file;
      this.file = null;
    },

    setParam(param, save_prev = false) {
      if (save_prev && !this.prev_param) {
        this.prev_param = _.cloneDeep(this.param);
      }
      this.param = param;
    },

    setFileParam(filename, base64, save_prev = false) {
      if (save_prev && !this.prev_param) {
        this.prev_param = _.cloneDeep(this.param);
      }
      this.param = {
        filename: filename, //with extension
        base64: base64,
      };

      console.log('setFileParam', this.param);
    },

    unsetNull() {
      this.param = _.cloneDeep(this.prev_param);
      this.prev_param = null;
      this.file = this.prev_file;
      this.prev_file = null;
    },

    onFile() {
      console.log('file', this.file);
      if (!_.isNil(this.file)) {
        this.loadingBase64 = true;
        // console.log('onFile');
        let is_data_url = this.propertyType == 'blob' ? false : true; //dataurl only for base64 propertyType
        this.getBase64(this.file, is_data_url)
          .then(data => {
            // console.log(data);
            // this.params.image_base64 = data;
            if (this.allow_crop) {
              this.$refs.croppieRef.bind({
                url: data,
              });
            }
            // this.param = data; //convert to base64
            if (this.propertyType == 'file') {
              this.setFileParam(this.file['name'], data, true);
            } else {
              this.setParam(data, true);
            }
            this.loadingBase64 = false;
          })
          .catch(error => {
            // this.param = null;
            this.setNull();
            this.loadingBase64 = false;
          });
      }
    },

    onCrop() {
      if (this.allow_crop) {
        // console.log('crop!');
        let options = {
          format: 'jpeg',
          quality: 0.8,
          size: { width: 200, height: 200 },
          type: 'base64',
          // circle: true, //don't actually crop circle
        };
        this.$refs.croppieRef.result(options, output => {
          // console.log(output);
          // this.param = output;
          if (this.propertyType == 'file') {
            this.setFileParam(this.file['name'], output);
          } else {
            this.setParam(output);
          }
          // this.$forceUpdate();
        });
      }
    },

    handleClickForDelete($event) {
      if ($event.vertex) {
        this.$refs.polygon.$polygonObject
          .getPaths()
          .getAt($event.path)
          .removeAt($event.vertex);
      }
    },

    onPolygonChanged: function(mvcPaths) {
      // this.mvcPaths = mvcPaths

      let paths = [];
      for (let i = 0; i < mvcPaths.getLength(); i++) {
        let path = [];
        for (let j = 0; j < mvcPaths.getAt(i).getLength(); j++) {
          let point = mvcPaths.getAt(i).getAt(j);
          path.push({ lat: point.lat(), lng: point.lng() });
        }
        paths.push(path);
      }

      this.map.polygon = paths;

      function closeLoop(path) {
        return path.concat(path.slice(0, 1));
      }

      var geojson_polygon_string = JSON.stringify(
        {
          type: 'Polygon',
          coordinates: this.map.polygon.map(path => closeLoop(path.map(({ lat, lng }) => [lng, lat]))),
        },
        null,
        2
      );

      // console.log(geojson_polygon_string);

      this.param = JSON.parse(geojson_polygon_string);
    },

    setPoint(lngLatArray) {
      this.map.point.lng = lngLatArray[0];
      this.map.point.lat = lngLatArray[1];
      console.log('changed', this.map.point.lng, this.map.point.lat);
      this.map.center = Object.assign({}, this.map.point);
    },

    setPlace(place) {
      console.log('setPlace', place); //TDOO: need to find a nice way to update address, emit?
      if (place) {
        this.onPositionChanged(place.geometry.location);

        this.map.center = Object.assign({}, this.map.point);
      }
    },

    lngLatToGeojson(lngLatArray) {
      var geojson_point_object = {
        type: 'Point',
        coordinates: lngLatArray,
      };
      return geojson_point_object;
    },

    onPositionChanged(event) {
      console.log(event);
      this.map.point.lng = event.lng();
      this.map.point.lat = event.lat();

      this.param = this.lngLatToGeojson([this.map.point.lng, this.map.point.lat]);

      // var geojson_point_string = JSON.stringify(geojson_point_object);
      // console.log('geojson', geojson_point_string);
      // this.param = geojson_point_string;
    },

    readPolygonGeojson: function(polygonGeojson) {
      var v = JSON.parse(polygonGeojson);

      this.map.polygon = v.coordinates.map(linearRing => linearRing.slice(0, linearRing.length - 1).map(([lng, lat]) => ({ lat, lng })));

      //ref: https://github.com/xkjyeah/vue-google-maps/issues/180#issuecomment-321540684
      this.$refs.map.$mapPromise.then(map => {
        // console.log(this.map.polygon[0]);

        setTimeout(() => {
          const bounds = new google.maps.LatLngBounds();
          this.map.polygon[0].forEach(point => {
            bounds.extend(point);
          });
          map.fitBounds(bounds);
        }, 100);
      });
      //  this.map.center = this.map.polygon[0][0];
    },
    toggle() {
      this.isMapShow = this.isMapShow ? false : true;
      if(this.isMapShow) {
        this.isMapShown = true
      }
    },
  },
};
</script>

<style>
/* ref: https://github.com/xkjyeah/vue-google-maps/issues/420#issuecomment-398302937 */
.pac-container.pac-logo {
  z-index: 999999;
}

.nulled_switch .switch-slider::after {
  opacity: 0;
}
.nulled_switch .switch-slider::before {
  /* opacity: 0.5; */
  -webkit-transform: translateX(13px);
  transform: translateX(13px);
  /* border-color: #3a4146; */
}

.nulled_switch.switch-sm .switch-slider::before {
  -webkit-transform: translateX(9px);
  transform: translateX(9px);
}

.q-field__inner {
  padding: 0 !important;
}

.q-field,
.q-field__control {
  margin: 0 !important;
}

.toggle-btn {
  display: inline-block;
  padding: 7px 13px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 7px;
  box-shadow: 0 4px #999;
}
.toggle-btn:active {
  box-shadow: 0 2px #666;
  transform: translateY(2px);
}
.toggle-blue {
  background-color: lightskyblue;
}
.toggle-grey {
  background-color: lightgray;
}
</style>
<style lang="scss" scoped>
.form_input  {
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #f7f7f7 !important;
    opacity: 1 !important;
    cursor: not-allowed !important;
    color: #000;
  }

  input[disabled][type='file'] {
    //fixes Quasar causing file input to look bad when disabled
    opacity: 0 !important;
  }

  input[type='file' i] {
    cursor: pointer;
  }

  // $json_editor_menu_color: #3883fa;
  // $json_editor_menu_color: #2f353a;
  // $json_editor_menu_color: #6f7c86;
  // $json_editor_menu_color: #85898b;
  // $json_editor_menu_color: #ebebeb;
  $json_editor_menu_color: #b8b7b7;
  $json_editor_menu_height: 25px;

  div.jsoneditor-menu {
    height: $json_editor_menu_height;
    background-color: $json_editor_menu_color;
    border-bottom: 1px solid $json_editor_menu_color;

    a.jsoneditor-poweredBy {
      display: none;
    }
    & > button {
      // margin-top: calc((#{$json_editor_menu_height} - 35px)/2 );
      margin: 0;
      margin-top: -3px;
    }
  }
  div.jsoneditor {
    border: thin solid $json_editor_menu_color;
  }
  div.jsoneditor-outer.has-main-menu-bar {
    margin-top: -$json_editor_menu_height;
    padding-top: $json_editor_menu_height;
  }

  .switch-input:disabled ~ .switch-slider {
    opacity: 0.75 !important;
  }
  /* .switch-outline-success .switch-input:checked:disabled + .switch-slider {
    border-width: 2px;
  } */
  .switch-outline-success .switch-input + .switch-slider::before {
    border-width: 2px; //thicker if enabled
  }
  .switch-outline-success .switch-input:disabled + .switch-slider::before {
    border-width: 1px; //thinner if disabled
  }

  .date-time-picker  {
    .time-picker-column {
      height: 307px;
    }

    .time-picker-column {
      height: 307px;
    }
    .header-picker-hour.twelve {
      min-width: 105px; //fix for 12 hr clock
    }
  }

  img.circle {
    border-radius: 50%; //circle
  }

  .croppie {
    &.circle .cr-boundary {
      border-radius: 50%; //circle
    }

    .cr-slider {
      -webkit-appearance: none;
      /*removes default webkit styles*/
      /*border: 1px solid white; */ /*fix for FF unable to apply focus style bug */
      width: 300px;
      /*required for proper track sizing in FF*/
      max-width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: transparent;
    }

    .cr-slider::-webkit-slider-runnable-track {
      width: 100%;
      height: 3px;
      background: rgba(0, 0, 0, 0.5);
      border: 0;
      border-radius: 3px;
    }

    .cr-slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: #ddd;
      margin-top: -6px;
    }

    .cr-slider:focus {
      outline: none;
    }

    /*
.cr-slider:focus::-webkit-slider-runnable-track {
background: #ccc;
}
*/

    .cr-slider::-moz-range-track {
      width: 100%;
      height: 3px;
      background: rgba(0, 0, 0, 0.5);
      border: 0;
      border-radius: 3px;
    }

    .cr-slider::-moz-range-thumb {
      border: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: #ddd;
      margin-top: -6px;
    }

    /*hide the outline behind the border*/
    .cr-slider:-moz-focusring {
      outline: 1px solid white;
      outline-offset: -1px;
    }

    .cr-slider::-ms-track {
      width: 100%;
      height: 5px;
      background: transparent;
      /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
      border-color: transparent; /*leave room for the larger thumb to overflow with a transparent border */
      border-width: 6px 0;
      color: transparent; /*remove default tick marks*/
    }
    .cr-slider::-ms-fill-lower {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    }
    .cr-slider::-ms-fill-upper {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    }
    .cr-slider::-ms-thumb {
      border: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: #ddd;
      margin-top: 1px;
    }
    .cr-slider:focus::-ms-fill-lower {
      background: rgba(0, 0, 0, 0.5);
    }
    .cr-slider:focus::-ms-fill-upper {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
